// 截击数据
import {CustomCanvas} from '../utils/canvas';
import {CanvasRadar} from '../utils/canvas-radar';
import {CanvasArc} from '../utils/canvas-arc';
import {getForehandBackhandRatio} from '../utils/forehand-backhand-ratio';
import {drawPointMap} from './point-map';
import {createCanvas} from '../utils/canvas-util';


export const DrawVolley = function (canvas) {
    const scale = 3;
	const width = 327;
	const height = 146;

    canvas = createCanvas(canvas, width, height, scale);

    const customCanvas = new CustomCanvas(canvas, {scale});

    function handleData(pointList) {
        const successList = [];
        pointList.forEach(item => {
            const {x, y, region, targetRegion, isOut} = item;
            const isIn = isOut !== undefined ? !isOut : x >= 0 && x <= 1 && y >= 0 && y <= 1;
            if (isIn) {
                successList.push(item);
            }
        });

        return [successList];
    }

    this.draw = async (data) => {
        customCanvas.clear();
        const {forehand, backhand} = data || {};
        

        const [forehandSuccess] = handleData(forehand);

        const [backhandSuccess] = handleData(backhand);

        const imgurl = getForehandBackhandRatio(null, {
            title: '截击成功率(%)',
			ratioList: [backhandSuccess.length / (backhand.length || 1), forehandSuccess.length / (forehand.length || 1)],
			subTitleList: ['反手', '正手'],
        });
        await customCanvas.drawImage(imgurl, 15, 15, 132, 132);



        const mapImg = await drawPointMap([
            {
                text: '正手',
                color: '#DDE934',
                list: forehand,
            },
            {
                text: '反手',
                color: '#55D7AB',
                list: backhand,
            },
            {
                text: '界内',
                color: '#111',
            },
            {
                text: '界外',
                color: '#111',
                isRing: true,
            }
        ]);
        await customCanvas.drawImage(mapImg, 182, 5, 138, 150);

    };
    this.toDataURL = customCanvas.toDataURL;
    this.toTempFilePath = customCanvas.toTempFilePath;
    this.getSize = () => ({
        width,
        height,
        scale,
    });
};

