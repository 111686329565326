
import {CustomCanvas} from '../utils/canvas';
import {createCanvas} from '../utils/canvas-util';

export const drawPointMap = async function (dataList, config) {
    const {mapScale = 0.7, type} = config || {};
    const isWechat = typeof window === 'undefined' && typeof getApp !== 'undefined';
    const areaimg = isWechat ? 'https://aicoach.karryai.tech/static/images/img28.png' : '/static/images/img28.png';
    const scale = 3;
    const width = 414;
    const height = 391;
    const textHeight = 60;

    const canvas = createCanvas(undefined, width, (height + Math.ceil(dataList.length / 4) * textHeight), scale);

    // 球场边界
    const mapBoundary = {
        left: 77,
        bottom: 360,
        top: 6,
        right: 326, 
    };

    const customCanvas = new CustomCanvas(canvas, {scale});

    function renderPointList(dataItem) {
        if (!dataItem?.list) {
            return;
        }
        // boundary
        const mapWidth = (mapBoundary.right - mapBoundary.left) * mapScale;
        const mapHeight = (mapBoundary.bottom - mapBoundary.top) * mapScale;
        const boundaryLeft = mapBoundary.left + (mapBoundary.right - mapBoundary.left - mapWidth) / 2;
        const {text, color, list} = dataItem;
        list.forEach(item => {
            const {x, y, isOut, isSuccess} = item;
            const coordinateIn = x >= 0 && x <= 1 && y >= 0 && y <= 1;
            let isIn = isOut !== undefined ? !isOut : coordinateIn;
            if (type === 'serve') {
                isIn = isSuccess !== undefined ? isSuccess : coordinateIn;
            }
            const left = boundaryLeft + x * mapWidth;
            const top = mapBoundary.bottom - y * mapHeight;
            if (isIn) {
                customCanvas.fillRoundByCenter(left, top, 6, color);
            } else {
                customCanvas.strokeRoundByCenter(left, top, 6, color, 12);
            }
        });
    }

    async function render() {
        const mapWidth = width * mapScale;
        const mapHeight = height * mapScale;
        await customCanvas.drawImage(areaimg, (width - mapWidth) / 2, height - mapHeight, mapWidth, mapHeight);
        let left = 10;
        dataList.forEach((item, index) => {
            const {text, color, isRing, list} = item;
            const line = Math.floor(index / 4);
            const top = line * textHeight + height + 10;
            if (index % 4 === 0) {
                left = 18;
                if (dataList?.length === 2) {
                    left = 120;
                }
            }
            if (isRing) {
                customCanvas.strokeRound(left, top + 8, 6, color, 12);
            } else {
                customCanvas.fillRound(left, top + 8, 6, color);
            }
            left += 15;
            let textRes = customCanvas.fillText(text, color, 26, left, top + 18);
            left += textRes.width + 30;
            renderPointList(item);
        });
    }
    await render();

    return canvas.toDataURL('image/png');
};


