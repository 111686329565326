
import {CustomCanvas} from '../utils/canvas';
import {CanvasRadar} from '../utils/canvas-radar';
import {CanvasArc} from '../utils/canvas-arc';
import {getForehandBackhandRatio} from '../utils/forehand-backhand-ratio';
import {drawPointMap} from './point-map';
import {createCanvas} from '../utils/canvas-util';


// 底线-姿态
export const DrawPosture = function (canvas) {
    const scale = 3;
	const width = 327;
	const height = 247;

    canvas = createCanvas(canvas, width, height, scale);

    const customCanvas = new CustomCanvas(canvas, {scale});

    this.draw = async (data) => {
        customCanvas.clear();
        const {forehand, backhand} = data;

        customCanvas.fillText('反手挥拍完整度', '#333333', 12, 2, 40);
        customCanvas.fillText('正手挥拍完整度', '#333333', 12, 234, 40);

        customCanvas.fillTextByHorizontalCenter((backhand.integrity || 0).toFixed(0) + '%', '#55D7AB', 32, 47, 80);
        customCanvas.fillTextByHorizontalCenter((forehand.integrity || 0).toFixed(0) + '%', '#DDE934', 32, 279, 80);

        const serveCount = forehand.count + backhand.count;
        const canvasArc = new CanvasArc(canvas, {
            center: [162 * scale, 65 * scale],
            maxRadius: 118 * scale / 2,
        });
        canvasArc.setData([{
            angle: 360 * forehand.count / serveCount,
            color: '#DDE934',
            bgColor: '#55D7AB',
            backLineWidth: 5 * scale,
            dataLineWidth: 8 * scale,
        }]);

        customCanvas.fillText('击球次数', '#333333', 12, 142, 43, true);
        customCanvas.fillText('反手', '#999', 10, 132, 64);
        customCanvas.fillText('正手', '#999', 10, 173, 64);
        customCanvas.fillTextByHorizontalCenter(backhand.count, '#55D7AB', 20, 140, 92, true);
        customCanvas.fillTextByHorizontalCenter(forehand.count, '#DDE934', 20, 183, 92, true);

        customCanvas.fillRect(0, 149, width, 1, '#EEEEEE');

        customCanvas.fillText('击球点分析', '#333333', 12, 134, 171, true);
        await customCanvas.drawImage('https://aicoach.karryai.tech/static/images/img35.png', 198, 161, 12, 12);
        customCanvas.fillText('反手', '#333', 12, 12, 233);
        customCanvas.fillText('正手', '#333', 12, 291, 233);

        customCanvas.fillRect(51, 230, 224, 1, '#ccc');
        customCanvas.fillRect(163, 188, 1, 68, '#ccc');

        customCanvas.fillRound(163, 230, 8, '#ccc');
        customCanvas.fillRound(163, 230, 7, '#fff');

        customCanvas.drawLines([{x: 275 - 5, y: 230 - 5}, {x: 275, y: 231}, {x: 275 - 5, y: 230 + 6}], '#ccc');
        customCanvas.drawLines([{x: 163 - 5, y: 188 + 5}, {x: 164, y: 188}, {x: 163 + 6, y: 188 + 5}], '#ccc');

        // 250
        const pointConfig = {
            centerX: 163,
            centerY: 230,
            xRadius: 112,
            yRadius: 42,
            xMaxRadius: 250,
            yMaxRadius: 150,
        };
        drawPoints(forehand.hittingPoints, {
            ...pointConfig,
            color: '#DDE934',
        });

        drawPoints(backhand.hittingPoints, {
            ...pointConfig,
            color: '#55D7AB'
        });

    };

    function drawPoints(list, config) {
        const {centerX, centerY, xRadius, yRadius, xMaxRadius, yMaxRadius, color} = config;
        list.forEach(item => {
            const {x, y} = item;
            const left = centerX + xRadius * x / xMaxRadius;
            const top = centerY - yRadius * y / yMaxRadius;

            customCanvas.fillRound(left, top, 3, '#fff');
            customCanvas.fillRound(left, top, 2, color);
        });
    }

    this.toDataURL = customCanvas.toDataURL;
    this.toTempFilePath = customCanvas.toTempFilePath;

    this.getSize = () => ({
        width,
        height,
        scale,
    });
};
