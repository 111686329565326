// 发球技术
import {CustomCanvas} from '../utils/canvas';
import {CanvasRadar} from '../utils/canvas-radar';
import {CanvasArc} from '../utils/canvas-arc';
import {getForehandBackhandRatio} from '../utils/forehand-backhand-ratio';
import {drawPointMap} from './point-map';
import {serveHandle} from '../utils/data-util';
import {createCanvas} from '../utils/canvas-util';

export const DrawServe = function(canvas) {
    const scale = 3;
	const width = 327;
	const height = 218;

    canvas = createCanvas(canvas, width, height, scale);

    const customCanvas = new CustomCanvas(canvas, {scale});


    this.draw = async (serveList) => {
        customCanvas.clear();

        const {
            serve1Ratio, // 一发成功率
            serve2Ratio, // 二发成功率
            serve1Success, // 一发成功数
            serve2Success, // 二发成功数
            serve1Count, // 一发数量
            serve2Count, // 二发数量
            speed1Ave, // 一发平均球速
            speed2Ave, // 二发平均球速
            oneList, // 一发数据
            twoList, // 二发数据 
        } = serveHandle(serveList);


        const imgurl = getForehandBackhandRatio(null, {
            title: '发球成功率(%)',
			ratioList: [serve1Ratio / 100, serve2Ratio / 100],
			subTitleList: ['一发', '二发'],
        });
        await customCanvas.drawImage(imgurl, 15, 15, 132, 132);

        const aveSpeed1 = speed1Ave;
        const aveSpeed2 = speed2Ave;
        const allAveSpeed = (aveSpeed1 + aveSpeed2) || 1;

        customCanvas.fillRect(0, 163, width, 1, '#EEEEEE');
        customCanvas.fillTextByHorizontalCenter('平均发球速率（km/h）', '#333333', 12, width / 2, 187);
        customCanvas.fillTextByHorizontalCenter('一发', '#999', 10, 29, 190);
        customCanvas.fillTextByHorizontalCenter(Math.floor(aveSpeed1), '#55D7AB', 20, 29, 212);
        customCanvas.fillTextByHorizontalCenter('二发', '#999', 10, 299, 190);
        customCanvas.fillTextByHorizontalCenter(Math.floor(aveSpeed2), '#DDE934', 20, 299, 212);

        const lineWidth = 200;

        aveSpeed1 > 0 && customCanvas.fillRound(64, 200, 4, '#55D7AB');
        aveSpeed2 > 0 && customCanvas.fillRound(lineWidth + 64, 200, 4, '#DDE934');
        const success1Width = lineWidth * aveSpeed1 / allAveSpeed;
        customCanvas.fillRect(64, 196, success1Width, 8, '#55D7AB');
        const success2Width = lineWidth * aveSpeed2 / allAveSpeed;
        customCanvas.fillRect(64 + success1Width, 196, success2Width, 8, '#DDE934');
        if (aveSpeed1 === allAveSpeed) {
            customCanvas.fillRound(lineWidth + 64, 200, 4, '#55D7AB');
        } else if (aveSpeed2 === allAveSpeed) {
            customCanvas.fillRound(64, 200, 4, '#DDE934');
        }

        const mapImg = await drawPointMap([
            {
                text: '一发',
                color: '#55D7AB',
                list: oneList,
            },
            {
                text: '二发',
                color: '#DDE934',
                list: twoList,
            },
            {
                text: '界内',
                color: '#111',
            },
            {
                text: '界外',
                color: '#111',
                isRing: true,
            }
        ], {type: 'serve'});
        await customCanvas.drawImage(mapImg, 182, 5, 138, 150);

    };
    this.toDataURL = customCanvas.toDataURL;
    this.toTempFilePath = customCanvas.toTempFilePath;
    this.getSize = () => ({
        width,
        height,
        scale,
    });
};
