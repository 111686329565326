// 第一落点
import {CustomCanvas} from '../utils/canvas';
import {CanvasRadar} from '../utils/canvas-radar';
import {CanvasArc} from '../utils/canvas-arc';
import {getForehandBackhandRatio} from '../utils/forehand-backhand-ratio';
import {drawPointMap} from './point-map';
import {dropShotHandle} from '../utils/data-util';
import {createCanvas} from '../utils/canvas-util';

export const DrawDropShot = function (canvas) {
    const scale = 3;
	const width = 327;
	const height = 232;

    canvas = createCanvas(canvas, width, height, scale);

    const customCanvas = new CustomCanvas(canvas, {scale});

    this.draw = async (data) => {
        // const {jump1, jump2, jump3, jump4} = data;
        customCanvas.clear();

        const formatData = dropShotHandle(data);
        console.log('formatData====',formatData)
        const {
            jump1,
            jump2,
            jump3,
            jump1HighRatio,
            jump2HighRatio,
            jump3HighRatio,
    
            jump1InRatio,
            jump2InRatio,
            jump3InRatio,
        } = dropShotHandle(data);

        customCanvas.fillText('优质球占比', '#333', 10, 51, 12, true);
        customCanvas.fillText('落点分析', '#333', 10, 224, 12, true);

        const canvasArc = new CanvasArc(canvas, {
            center: [76 * scale, 90 * scale],
            maxRadius: 60 * scale,
            roundSpace: 5 * scale,
            lineWidth: 5 * scale,
        });

        canvasArc.setData([
            {
                angle: 360 * jump1HighRatio / 100,
                color: '#53DBAD',
                bgColor: '#EEFBF3',
            },
            {
                angle: 360 * jump2HighRatio / 100,
                color: '#DDE934',
                bgColor: '#F8FBD6',
            },
            {
                angle: 360 * jump3HighRatio / 100,
                color: '#FFCD45',
                bgColor: '#FDF9E1',
            },
        ]);

        customCanvas.fillRound(31, 171, 4, '#53DBAD');
        customCanvas.fillText('一跳落于发球区内', 'rgba(0, 0, 0, 0.7)', 9, 43, 173);
        customCanvas.fillText(`${Math.floor(jump1InRatio)}%`, 'rgba(0, 0, 0, 0.7)', 9, 120, 173);
        customCanvas.fillRound(31, 189, 4, '#DDE934');
        customCanvas.fillText('二跳落于发球区内', 'rgba(0, 0, 0, 0.7)', 9, 43, 192);
        customCanvas.fillText(`${Math.floor(jump2InRatio)}%`, 'rgba(0, 0, 0, 0.7)', 9, 120, 192);
        customCanvas.fillRound(31, 207, 4, '#FFCD45');
        customCanvas.fillText('三跳落于发球区内', 'rgba(0, 0, 0, 0.7)', 9, 43, 210);
        customCanvas.fillText(`${Math.floor(jump3InRatio)}%`, 'rgba(0, 0, 0, 0.7)', 9, 120, 210);

        const dataList = [
            {
                text: '一跳',
                color: '#55D7AB',
                list: jump1,
            },
            {
                text: '二跳',
                color: '#DDE934',
                list: jump2,
            },
            {
                text: '三跳',
                color: '#FFCD45',
                list: jump3,
            },
            {
                text: '界内',
                color: '#111',
                list: [],
            },
            {
                text: '界外',
                color: '#111',
                isRing: true,
                list: [],
            },
        ];
        const mapimage = await drawPointMap(dataList);
        await customCanvas.drawImage(mapimage, 181, 32, 138, 169);

    };

    this.toDataURL = customCanvas.toDataURL;
    this.toTempFilePath = customCanvas.toTempFilePath;

    this.getSize = () => ({
        width,
        height,
        scale,
    });
};








