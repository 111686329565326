import './index.css';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Form, Input, Tabs, Space, Slider, Radio, Switch, Table, Spin, Select, Modal, Col, Row, message } from "antd";
import {DrawFirstLand} from '../../canvas_util/draw-first-land';




export const PointSelect = props => {
	const {clickHandle, pointList, pointClick, type, globalIn} = props;
	const changdiRef = useRef();
	const jieneiRef = useRef();
	const serveRef = useRef();




	useEffect(() => {}, []);


	const positionClick = (event) => {
		const result = {};
		const target = event.target;
		const react = changdiRef.current.getBoundingClientRect();
		const {width, height} = react;
		const top = event.pageY - document.documentElement.scrollTop - react.top;
		const left = event.pageX - document.documentElement.scrollLeft - react.left;

		result.outArea = target === changdiRef.current;
		result.serveArea = target === serveRef.current;
		result.inArea = result.serveArea || target === jieneiRef.current;

		const {clientWidth, clientHeight, offsetLeft, offsetTop, offsetWidth, offsetHeight} = jieneiRef.current;
		const bottom = height - offsetTop - offsetHeight;
		const clickY = height - top;

		// console.log(clientWidth, clientHeight, offsetLeft, offsetTop, offsetWidth, offsetHeight)
		// console.log('------',left, top, clickY, bottom)

		let ww = left - offsetLeft;

		let hh = clickY - bottom;


		const x = ww / offsetWidth;
		const y = hh / offsetHeight;
		clickHandle?.({x, y});
	};

	const rightClick = (index, event) => {
		event.stopPropagation();
		event.preventDefault();
		pointClick?.(index);
	};

	// const changdiStyle = {
	// 	backgroundSize: `${scale * 100}% ${scale * 100}%`,
	// 	backgroundPosition: 'center bottom'
	// };
	// const jieneiStyle = {
	// 	width: `${60 * scale}%`, // 60
	// 	height: `${90 * scale}%`, // 90
	// 	left: `${(100 - 60 * scale)/2}%`, // 18.5
	// 	top: `${30.5}%`, // 1.5
	// };

	return <>
		<div ref={changdiRef} className='changdi changdi-scale' onClick={positionClick} onContextMenu={(event) => {event.stopPropagation();event.preventDefault();}}>
			<div ref={jieneiRef} className='changdi-jienei'>
				<div ref={serveRef} className='changdi-serve'></div>
				{
					pointList.map((item, index) => {
						const {isOut, x, y, isBackhand, isSuccess} = item;
						let out = isOut;
						if (type === 'serve') {
							out = !isSuccess;
						}
						if (globalIn !== undefined) {
							out = !globalIn;
						}
						return <div onContextMenu={(event) => rightClick(index, event)} className={`changdi-point ${out?'changdi-out':''} ${isBackhand?'changdi-backhand':''} changdi-jump${item.jump} changdi-serve${item.serve}`} style={{
							left: `${x * 100}%`,
							bottom: `${y * 100}%`,
						}}></div>;
					})
				}
			</div>
		</div>
	</>
};