import './index.css';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Form, Input, Tabs, Space, Radio, InputNumber, Flex, Slider, Switch, Table, Spin, Select, Modal, Col, Row, message } from "antd";
import {DrawServe} from '../../canvas_util/draw-serve';
import {PointSelect} from '../point_select';


export const DrawServeView = props => {
	const dataType = 'serve';
	const {data, onChange} = props;
	const canvasRef = useRef();
	const drawObjRef = useRef();
	const [renderData, setRenderData] = useState([]);
	const [showPointList, setShowPointList] = useState([]);
	const [itemData, setItemData] = useState();

	const setNeweItemData = () => {
		setItemData({isSuccess: false, serve: 1, speed: 0});
	};

	const inputChange = (type, value) => {
		itemData[type] = value;
		setItemData({...itemData})
	};

	const renderCanvas = data => {
		drawObjRef.current = drawObjRef.current || new DrawServe(canvasRef.current);
		drawObjRef.current.draw(data);
	};

	// 点击位置回调
	const pointClick = data => {
		const {x, y} = data;
		const isOut = x < 0 || x > 1 || y < 0 || y > 1;
		setItemData({...itemData, x, y, isOut});
	};


	const removePoint = (index) => {
		const forehandLength = renderData?.forehand?.length || 0;
		const backhandLength = renderData?.backhand?.length || 0;
		if (index < forehandLength) {
			renderData.forehand.splice(index, 1);
		} else if (index < (forehandLength + backhandLength)) {
			renderData.backhand.splice(index - forehandLength, 1);
		} else {
			return;
		}
		setRenderData({...renderData});
	};

	const addPoint = () => {
		const keyList = ['isOut', 'isSuccess', 'x', 'y'];
		for (let i = 0; i < keyList.length; i++) {
			const key = keyList[i];
			if (itemData[key] === undefined) {
				alert(key + '未设置');
				return;
			}
		}
		renderData.push(itemData);
		setNeweItemData();
		setRenderData([...renderData]);
	};

	const renderPointList = () => {
		if (!renderData) {
			return;
		}
		const pointList = [...renderData];
		if (itemData?.x !== undefined) {
			pointList.push(itemData);
		}
		setShowPointList(pointList);
	};

	useEffect(() => {
		// 初始化
		setNeweItemData();
	}, []);

	useEffect(() => {
		// 更新数据
		setRenderData(data || []);

	}, [data]);

	useEffect(() => {
		// 渲染落点
		renderPointList();
	}, [itemData]);

	useEffect(() => {
		// 渲染canvas
		if (!renderData) {
			return;
		}
		renderCanvas(renderData || {});
		renderPointList();

		onChange?.(dataType, renderData);
	}, [renderData]);


	const depthFormatter = (value) => `${value}cm`;

	return <>
		<Row>
			<Col span={12}>
				<Space>
					<PointSelect clickHandle={pointClick} type="serve" pointList={showPointList} pointClick={removePoint}/>
				</Space>
				<div style={{fontSize: '12px', color: '#999', lineHeight: '14px'}}>点右键删除点</div>
				<div><InputNumber onChange={(value) => inputChange('speed', value)} value={itemData?.speed || 0} addonBefore='球速' addonAfter='km/h' /></div>
				<Space>
					<Radio.Group
						value={itemData?.serve || 1}
						options={[{ value: 1, label: '一发' }, { value: 2, label: '二发' }]}
						onChange={(event) => inputChange('serve', event.target.value)}
					/>
					<Checkbox checked={!!itemData?.isSuccess} onChange={(event) => inputChange('isSuccess', event.target.checked)}>发球成功</Checkbox>
				</Space>
				<div><Button size='small' type="primary" onClick={addPoint}>添加</Button></div>
			</Col>
			<Col span={12}><canvas style={{width: '100%'}} ref={canvasRef}/></Col>
		</Row>
	</>
};