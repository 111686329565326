import './index.css';
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input, Tabs, Space, Modal, message } from "antd";
import { loginOut } from '../../util/loginUtil';
import { changePassword } from '../../util/requestUtil';
import { ChangGuan } from '../changguan';
import { XiaoJie } from '../xiaojie';
import { UserPage } from '../user_page';
import {Machine} from '../machine';
import {OrderPage} from '../order_page';
import {ChatPage} from '../chat_page';


export const Page = props => {
	const editOrderKey = 'editOrderKey';
	const [form] = Form.useForm();
	const [currentKey, setCurrentKey] = useState('pageTab1');
	const [isModifyPassword, setModifyPassword] = useState(false);
	const [editOrderData, setEditOrderData] = useState();

	const onFinish = async (values) => {
		if (values.newPassword !== values.confirmPassword) {
			message.error('新密码与确认密码不同!');
			return;
		}
		setModifyPassword(false);
		const res = await changePassword(values.oldPassword, values.newPassword);
		if (res?.error === 0) {
			message.success('修改成功');
		} else {
			message.error('修改失败');
		}
	};

	const mofifyPasswordFn = () => {
		form.submit();
	};

	const cancelModifyPassword = () => {
		setModifyPassword(false);
	};

	const exitLogin = () => {
		loginOut();
		props.loginChange?.();
	};

	const onChange = (key) => {
		setCurrentKey(key);
	};

	const editOrder = (id, orderData) => {
		setEditOrderData(orderData);
		setCurrentKey(editOrderKey);
	};

	useEffect(() => {
		if (currentKey !== editOrderKey) {
			setEditOrderData(undefined);
		}
	}, [currentKey]);

	const tabsList = [
		{
			key: 'pageTab1',
			label: '场馆数据',
			children: <>{currentKey === 'pageTab1' && <ChangGuan />}</>,
		},
		{
			key: 'pageTab2',
			label: '评测管理',
			children: <>{currentKey === 'pageTab2' && <XiaoJie />}</>,
		},
		{
			key: 'pageTab3',
			label: '发球机调试',
			children: <>{currentKey === 'pageTab3' && <Machine />}</>
		},
		{
			key: 'pageTab4',
			label: '订单管理',
			children: <>{currentKey === 'pageTab4' && <OrderPage editOrder={editOrder} />}</>
		},
		{
			key: 'pageTab5',
			label: '用户管理',
			children: <>{currentKey === 'pageTab5' && <UserPage />}</>
		},
		{
			key: editOrderKey,
			label: '图表编辑',
			children: <>{currentKey === editOrderKey && <ChatPage orderData={editOrderData} />}</>
		},
		// {
		// 	key: 'pageTab4',
		// 	label: '日志',
		// 	children: <>{currentKey === 'pageTab4' && <Log />}</>
		// }
	];


	return <><div>
		<Space style={{padding: '20px'}}>
			<Button onClick={exitLogin}>退出登录</Button>
			<Button onClick={() => setModifyPassword(true)}>修改密码</Button>
		</Space>

		<div style={{padding: '20px'}}>
			<Tabs defaultActiveKey="1" activeKey={currentKey} items={tabsList} onChange={onChange} />
		</div>
	</div>
	<Modal okText='修改' title='修改密码' cancelText='取消' open={isModifyPassword} onCancel={cancelModifyPassword} onOk={mofifyPasswordFn}>
		<Form
			form={form}
            name="pass"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            onFinish={onFinish}
            autoComplete="off"
		>
			<Form.Item
                label="旧密码"
                name="oldPassword"
                rules={[{ required: true, message: '请输入旧密码!' }]}
            ><Input.Password /></Form.Item>
			<Form.Item
                label="新密码"
                name="newPassword"
                rules={[{ required: true, message: '请输入新密码!' }]}
            ><Input.Password /></Form.Item>
			<Form.Item
                label="新密码确认"
                name="confirmPassword"
                rules={[{ required: true, message: '请确认新密码!' }]}
            ><Input.Password /></Form.Item>
		</Form>
	</Modal>
	</>
};