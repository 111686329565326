const imageCache = {};

export const createCanvas = (canvas, width, height, scale = 1) => {
    // 浏览器
    canvas = canvas || document.createElement('canvas');
    canvas.setAttribute('width', width * scale);
    canvas.setAttribute('height', height * scale);

    return canvas;
};


// 加载图片
export const loadImage = (imgUrl, canvas) => {
    return new Promise((resolve, reject) => {
        if (imageCache[imgUrl]) {
            resolve(imageCache[imgUrl]);
            return;
        }
        const bg = new Image();
        bg.src = imgUrl;
        bg.onload = () => {
            imageCache[imgUrl] = bg;
            resolve(bg);
        };
        bg.onerror = () => {
            reject(imgUrl);
        };
    });
};

export const canvasToTempFilePath = canvas => {
    return canvas.toDataURL('image/png');
};