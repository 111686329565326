
import {CustomCanvas} from '../utils/canvas';
import {CanvasRadar} from '../utils/canvas-radar';
import {CanvasArc} from '../utils/canvas-arc';
import {moveHandle} from '../utils/data-util';
import {createCanvas} from '../utils/canvas-util';


// 移动数据
export const DrawMove = function (canvas) {
    const scale = 3;
	const width = 324;
	const height = 244;

    canvas = createCanvas(canvas, width, height, scale);

    const customCanvas = new CustomCanvas(canvas, {scale});

    this.draw = async (move) => {
        customCanvas.clear();
        const {
            leftRight,
            frontBack,
            combination,
            pace,
        } = moveHandle(move);
        const canvasArc = new CanvasArc(canvas, {
            center: [78 * scale, 88 * scale],
            maxRadius: 60 * scale,
            roundSpace: 3.2 * scale,
            lineWidth: 5 * scale,
        });

        // const {angle, color, alpha = 1, bgColor, bgAlpha = 1, backLineWidth, dataLineWidth} = item;
        const paceDataList = [
			{title: '左右移动', score: leftRight, angle: 360 * leftRight / 100, color: '#5BC4E2', bgColor: '#EEFBF3'},
			{title: '前后移动', score: frontBack, angle: 360 * frontBack / 100, color: '#53DBAD', bgColor: '#5BC4E2', bgAlpha: 0.1},
			{title: '组合球', score: combination, angle: 360 * combination / 100, color: '#DDE934', bgColor: '#F8FBD6'},
			{title: '脚步调整', score: pace, angle: 360 * pace / 100, color: '#F5E269', bgColor: '#F5E269', bgAlpha: 0.2},
		];
        canvasArc.setData(paceDataList);

        paceDataList.forEach((item, index) => {
            const {title, score, color} = item;
            const top = 175 + index * 18;
            customCanvas.fillRound(24, top, 4, color);
            customCanvas.fillText(title, 'rgba(0,0,0,0.7)', 9, 36, top + 3);
            customCanvas.fillText(score, 'rgba(0,0,0,0.7)', 9, 100, top + 3);
        });

        customCanvas.fillTextByHorizontalCenter('移动脚步评估', '#333333', 12, 78, 12, true);
        customCanvas.fillTextByHorizontalCenter('站位热力图', '#333333', 12, 244, 12, true);

        move.standHeatmap && await customCanvas.drawImage(move.standHeatmap, 187, 29, 110, 206);
    };

    this.toDataURL = customCanvas.toDataURL;
    this.toTempFilePath = customCanvas.toTempFilePath;
    this.getSize = () => ({
        width,
        height,
        scale,
    });
};
