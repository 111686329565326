// 第一落点
import {CustomCanvas} from '../utils/canvas';
import {CanvasRadar} from '../utils/canvas-radar';
import {CanvasArc} from '../utils/canvas-arc';
import {getForehandBackhandRatio} from '../utils/forehand-backhand-ratio';
import {drawPointMap} from './point-map';
import {firstLandingPointsHandle} from '../utils/data-util';
import {createCanvas} from '../utils/canvas-util';

export const DrawFirstLand = function (canvas) {
    const scale = 3;
	const width = 327;
	const height = 242;

    canvas = createCanvas(canvas, width, height, scale);

    const customCanvas = new CustomCanvas(canvas, {scale});

    this.draw = async (firstLandingPoint) => {
        customCanvas.clear();

        const {
            forehandList,
            backhandList,
            forehandTarget, // 正手目标区域数量
            backhandTarget, // 反手目标区域数量
            forehandTargetRatio,
            backhandTargetRatio,
            forehandInRatio, // 正手界内率
            backhandInRatio,
            forehandAverageDepth, // 正手平均深度
            backhandAverageDepth, // 反手平均深度
        } = firstLandingPointsHandle(firstLandingPoint);


        const jieneiRatioImg = getForehandBackhandRatio(null, {
			title: '正反手界内率(%)',
			ratioList: [backhandInRatio / 100, forehandInRatio / 100],
			subTitleList: ['反手', '正手'],
		});
        await customCanvas.drawImage(jieneiRatioImg, 15, 20, 132, 132);

        customCanvas.fillText('正手方向控制(%)', '#333', 9, 15, 176, true);
        let tt = 185;
        customCanvas.fillRoundReactForPos(12, tt, 120, 6, 3, '#F8FBD6');
        let ww = 114 * forehandTargetRatio / 100;
        customCanvas.fillRoundReactForPos(12, tt, ww + 6, 6, 3, '#DDE934');
        customCanvas.fillText(forehandTargetRatio, '#DDE934', 12, 137, tt + 6, true);

        const depthMax = 80;

        customCanvas.fillText('正手平均深度(m)', '#333', 9, 182, 176, true);
        customCanvas.fillRoundReactForPos(179, tt, 120, 6, 3, '#F8FBD6');
        firstLandingPoint.forEach(item => {
            const {x, y, isBackhand, depth} = item;
            if (!isBackhand) {
                const left = 182 + 114 * depth / depthMax;
                customCanvas.fillRound(left, tt + 3, 3, 'rgba(221,233,51,0.4)');
            }
        });
        customCanvas.fillText(forehandAverageDepth, '#DDE934', 12, 182 + 120, tt + 7);

        customCanvas.fillText('反手方向控制(%)', '#333', 9, 15, 214, true);
        tt = 225;
        customCanvas.fillRoundReactForPos(12, tt, 120, 6, 3, 'rgba(85,215,171, 0.1)');
        ww = 114 * backhandTargetRatio / 100;
        customCanvas.fillRoundReactForPos(12, tt, ww + 6, 6, 3, '#55D7AB');
        customCanvas.fillText(backhandTargetRatio, '#1CAD7C', 12, 137, tt + 6, true);

        customCanvas.fillText('反手平均深度(m)', '#333', 9, 182, 214, true);
        customCanvas.fillRoundReactForPos(179, tt, 120, 6, 3, 'rgba(85,215,171, 0.1)');
        firstLandingPoint.forEach(item => {
            const {x, y, isBackhand, region, targetRegion, depth} = item;
            if (isBackhand) {
                const left = 182 + 114 * depth / depthMax;
                customCanvas.fillRound(left, tt + 3, 3, 'rgba(85,215,171,0.4)');
            }
        });
        customCanvas.fillText(backhandAverageDepth, '#1CAD7C', 12, 182 + 120, tt + 7);

        const dataList = [
            {
                text: '正手',
                color: '#DDE934',
                list: forehandList,
            },
            {
                text: '反手',
                color: '#55D7AB',
                list: backhandList,
            },
            {
                text: '界内',
                color: '#111',
                list: [],
            },
            {
                text: '界外',
                color: '#111',
                isRing: true,
                list: [],
            },
        ];
        const mapimage = await drawPointMap(dataList);
        await customCanvas.drawImage(mapimage, 181, 20, 138, 140);
        customCanvas.fillTextByHorizontalCenter('第一落点分析', '#333', 10, 248, 12, true);
    };
    this.toDataURL = customCanvas.toDataURL;
    this.toTempFilePath = customCanvas.toTempFilePath;
    this.getSize = () => ({
        width,
        height,
        scale,
    });
};








