import './index.css';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Form, Input, Tabs, Space, Radio, InputNumber, Flex, Slider, Switch, Table, Spin, Select, Modal, Col, Row, message } from "antd";
import {DrawSlice} from '../../canvas_util/draw-slice';
import {PointSelect} from '../point_select';




export const DrawSliceView = props => {
	const dataType = 'slice';
	const {data, onChange} = props;
	const canvasRef = useRef();
	const drawObjRef = useRef();
	const [renderData, setRenderData] = useState();
	const [showPointList, setShowPointList] = useState([]);
	const [itemData, setItemData] = useState({heightFromNet: 0});

	const setNeweItemData = () => {
		setItemData({heightFromNet: 0});
	};

	const inputChange = (type, value) => {
		itemData[type] = value;
		if (type === 'isBackhand') {
			itemData[type] = itemData[type] ? 1 : 0;
		}
		setItemData({...itemData})
	};

	const renderCanvas = data => {
		drawObjRef.current = drawObjRef.current || new DrawSlice(canvasRef.current);
		drawObjRef.current.draw(data);
	};

	// 点击位置回调
	const pointClick = data => {
		const {x, y} = data;
		const isOut = x < 0 || x > 1 || y < 0 || y > 1;
		setItemData({...itemData, x, y, isOut});
	};


	const removePoint = (index) => {
		const forehandLength = renderData?.forehand?.length || 0;
		const backhandLength = renderData?.backhand?.length || 0;
		if (index < forehandLength) {
			renderData.forehand.splice(index, 1);
		} else if (index < (forehandLength + backhandLength)) {
			renderData.backhand.splice(index - forehandLength, 1);
		} else {
			return;
		}
		setRenderData({...renderData});
	};

	const addPoint = () => {
		const keyList = ['heightFromNet', 'isOut', 'x', 'y'];
		for (let i = 0; i < keyList.length; i++) {
			const key = keyList[i];
			if (itemData[key] === undefined) {
				alert(key + '未设置');
				return;
			}
		}
		const isBackhand = itemData.isBackhand;
		if (!isBackhand) {
			renderData.forehand.push(itemData);
		} else {
			renderData.backhand.push(itemData);
		}
		setNeweItemData();
		setRenderData({...renderData});
	};

	const renderPointList = () => {
		if (!renderData) {
			return;
		}
		const pointList = [...renderData?.forehand, ...renderData?.backhand];
		if (itemData?.x !== undefined) {
			pointList.push(itemData);
		}
		setShowPointList(pointList);
	};

	useEffect(() => {
		// 初始化
		setNeweItemData();
	}, []);

	useEffect(() => {
		// 更新数据
		setRenderData(data || {
			forehand: [],
			backhand: [],
		});

	}, [data]);


	useEffect(() => {
		// 渲染canvas
		if (!renderData) {
			return;
		}
		renderCanvas(renderData || {});
		onChange?.(dataType, renderData);
	}, [renderData]);

	useEffect(() => {
		// 渲染落点
		renderPointList();
	}, [renderData, itemData]);


	const depthFormatter = (value) => `${value}cm`;

	return <>
		<Row>
			<Col span={12}>
				<Space>
					<PointSelect clickHandle={pointClick} pointList={showPointList} pointClick={removePoint}/>
				</Space>
				<div style={{fontSize: '12px', color: '#999', lineHeight: '14px'}}>点右键删除点</div>
				<Flex>
					<div style={{width: '106px'}}>过网高度{itemData?.heightFromNet || 0}cm:</div>
					<div style={{flex: 1, padding: '0 20px 0 0'}}>
						<Slider tooltip={{formatter: depthFormatter}} style={{writingMode: 'bt-lr'}} max={100} step={1} min={-100} onChange={(value) => inputChange('heightFromNet', value)} value={itemData?.heightFromNet || 0}/>
					</div>
				</Flex>
				<Space>
					<Radio.Group
						value={itemData.isBackhand || 0}
						options={[{ value: 0, label: '正手' }, { value: 1, label: '反手' },]}
						onChange={(event) => inputChange('isBackhand', event.target.value)}
					/>
				</Space>
				<div><Button size='small' type="primary" onClick={addPoint}>添加</Button></div>
			</Col>
			<Col span={12}><canvas style={{width: '100%'}} ref={canvasRef}/></Col>
		</Row>
	</>
};