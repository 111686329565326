
export const overallScoreHandle = ntrpData => {
    const {ntrp, forehand, backhand, serve, netFront, tactics, move} = ntrpData || {};
    const radarList = [
        {name: '正手', type: 'forehand', value: forehand, max: 5, min: 1},
        {name: '反手', type: 'backhand', value: backhand, max: 5, min: 1},
        {name: '发球', type: 'serve', value: serve, max: 5, min: 1},
        {name: '战术', type: 'tactics', value: tactics, max: 5, min: 1},
        {name: '网前', type: 'netFront', value: netFront, max: 5, min: 1},
        {name: '移动', type: 'move', value: move, max: 5, min: 1},
    ];
    const result = {
        ...ntrpData,
        radarList,
    };


    return result;
};

export const sportDataHandle = data => {
    const {
        sportDuration = 0, // 运动时长  单位：h
        averageBattingSpeed = 0, // 平均击球速度  单位：km/h
        moveDistance = 0,// 移动距离 单位：m
        battingCount = 0, // 击球次数  单位：次
        burnCalories = 0, // 消耗热量 单位：cal
    } = data || {};

    return {
        sportDuration: Number(sportDuration.toFixed(2)),
        averageBattingSpeed: Number(averageBattingSpeed.toFixed(0)),
        moveDistance: Number(moveDistance.toFixed(1)),
        battingCount,
        burnCalories: Number(burnCalories.toFixed(2)),
    };
};

// 第一落点
export const firstLandingPointsHandle = firstLandingPoint => {

    const forehandList = []; // 正手数据
    const backhandList = []; // 反手数据
    let forehandSuccess = 0; // 正手界内数
    let backhandSuccess = 0; // 反手界内数
    let forehandTarget = 0; // 正手目标区域数量
    let backhandTarget = 0; // 反手目标区域数量
    let forehandDepth = 0; // 正手深度
    let backhandDepth = 0; // 反手深度
    firstLandingPoint?.forEach?.(item => {
        const {
            x, y, // 坐标
            isInTargetRegion, // 是否目标区域
            isBackhand, // 是否反手
            depth, // 深度m 距离球网距离
            isOut, // 是否出界
        } = item;
        const isIn = isOut !== undefined ? !isOut : x >= 0 && x <=1 && y >=0 && y<=1;
        if (!isBackhand) {
            // 正手
            // forehandCount++;
            forehandList.push(item);
            isIn && forehandSuccess++;
            isIn && isInTargetRegion && forehandTarget++;
            forehandDepth += depth;
        } else {
            // 反手
            // backhandCount++;
            backhandList.push(item);
            isIn && backhandSuccess++;
            isIn && isInTargetRegion && backhandTarget++;
            backhandDepth += depth;
        }
    });

    const forehandCount = forehandList.length || 1; // 正手数量
    const backhandCount = backhandList.length || 1; // 反手数量

    const forehandInRatio = Math.floor(forehandSuccess * 100 / (forehandCount || 1)); // 正手界内率
    const backhandInRatio = Math.floor(backhandSuccess * 100 / (backhandCount || 1)); // 反手界内率

    const forehandAverageDepth = (forehandDepth / forehandCount).toFixed(1); // 正手平均深度
    const backhandAverageDepth = (backhandDepth / backhandCount).toFixed(1); // 反手平均深度

    const forehandTargetRatio = Math.floor(forehandTarget * 100 / (forehandSuccess || 1)); // 正手方向控制比例
    const backhandTargetRatio = Math.floor(backhandTarget * 100 / (backhandSuccess || 1)); // 正手方向控制比例

    return {
        forehandList,
        backhandList,
        forehandTarget,
        backhandTarget,
        forehandTargetRatio,
        backhandTargetRatio,
        forehandInRatio,
        backhandInRatio,
        forehandAverageDepth,
        backhandAverageDepth,
    };
};

// 力量 第二落点
export const secondLandingPointsHandle = dataList => {

    let forehandCount = 0;
    let backhandCount = 0;
    let forehandSpeed = 0;
    let backhandSpeed = 0;
    const forehandList = [];
    const backhandList = [];
    let forehandKineticChain = 0;
    let backhandKineticChain = 0;
    dataList?.forEach?.(item => {
        const {x, y, isBackhand, kineticChain, speed} = item;
        if (!isBackhand) {
            forehandCount++;
            forehandSpeed += speed;
            forehandList.push(item);
            forehandKineticChain += kineticChain;
        } else {
            backhandCount++;
            backhandSpeed += speed;
            backhandList.push(item);
            backhandKineticChain += kineticChain;
        }
    });
    forehandCount = forehandCount || 1;
    backhandCount = backhandCount || 1;

    forehandKineticChain = Math.floor(forehandKineticChain / forehandCount);
    backhandKineticChain = Math.floor(backhandKineticChain / backhandCount);

    forehandSpeed = Math.floor(forehandSpeed / forehandCount);
    backhandSpeed = Math.floor(backhandSpeed / backhandCount);

    return {
        forehandKineticChain, // 正手动力链分
        backhandKineticChain, // 反手动力链分
        forehandList,
        backhandList,
        forehandSpeed, // 正手平均球速
        backhandSpeed, // 反手平均球速
    };
};

// 姿态
export const postureHandle = data => {
    const {forehand, backhand} = data || {};

    forehand && (forehand.integrity = Math.floor(forehand?.integrity));
    backhand && (backhand.integrity = Math.floor(backhand?.integrity));

    return {
        forehand,
        backhand,
        forehandIntegrity: forehand?.integrity || 0,
        backhandIntegrity: backhand?.integrity || 0,
        forehandCount: forehand?.count || 0,
        backhandCount: backhand?.count || 0,
    };
};

// 切削
export const sliceHandle = sliceData => {
    const {forehand, backhand} = sliceData || {};

    let maxHeightFromNet = 0;
    let minHeightFromNet = 0;
    let forehandDepth = 0; // 正手高度
    forehand?.forEach?.(point => {
        const {heightFromNet} = point;
        maxHeightFromNet = Math.max(maxHeightFromNet, heightFromNet);
        minHeightFromNet = Math.min(minHeightFromNet, heightFromNet);
        forehandDepth += heightFromNet;
    });
    let backhandDepth = 0; // 反手深度
    backhand?.forEach?.(point => {
        const {heightFromNet} = point;
        maxHeightFromNet = Math.max(maxHeightFromNet, heightFromNet);
        minHeightFromNet = Math.min(minHeightFromNet, heightFromNet);
        backhandDepth += heightFromNet;
    });

    const forehandHeightAve = Math.floor(forehandDepth / (forehand?.length || 1)) || 0;
    const backhandHeightAve = Math.floor(backhandDepth / (backhand?.length || 1)) || 0;

    return {
        forehandHeightAve, // 正手平均深度
        backhandHeightAve, // 反手平均深度
        forehand,
        backhand,
        maxHeightFromNet, // 最大过网高度
        minHeightFromNet, // 最小过网高度
    };
};

// 小球
export const dropShotHandle = data => {
    const {jump1, jump2, jump3} = data || {};

    const jump1Data = formatList(jump1);
    const jump2Data = formatList(jump2);
    const jump3Data = formatList(jump3);

    return {
        jump1,
        jump2,
        jump3,
        jump1HighRatio: Math.floor(jump1Data?.highRatio * 100) || 0,
        jump2HighRatio: Math.floor(jump2Data?.highRatio * 100) || 0,
        jump3HighRatio: Math.floor(jump3Data?.highRatio * 100) || 0,

        jump1InRatio: Math.floor(jump1Data?.inRatio * 100) || 0,
        jump2InRatio: Math.floor(jump2Data?.inRatio * 100) || 0,
        jump3InRatio: Math.floor(jump3Data?.inRatio * 100) || 0,
    };

    function formatList(list) {
        const result = {
            count: 0,
            highCount: 0,
            forehand: [],
            backhand: [],
            inRatio: 0,
        };
        let inCount = 0;
        list.forEach(item => {
            const {isHighQuality, isBackhand, isOut} = item;
            if (!isBackhand) {
                result.forehand.push(item);
            } else {
                result.backhand.push(item);
            }
            result.count++;
            isHighQuality && result.highCount++;
            inCount += isOut ? 0 : 1;
        });
        result.count = result.count || 1;
        result.highRatio = result.highCount / result.count;
        result.inRatio = inCount / result.count;
        return result;
    }
};

// 发球
export const serveHandle = serveList => {
    let serve1All = 0;
    let serve1Success = 0;
    let serve2All = 0;
    let serve2Success = 0;
    let speed1 = 0;
    let speed2 = 0;
    const oneList = [];
    const twoList = [];
    serveList?.forEach?.(item => {
        const {x, y, isSuccess, speed, serve} = item;
        if (serve === 1) {
            serve1All++;
            serve1Success += isSuccess ? 1 : 0;
            speed1 += speed;
            oneList.push(item);
        } else if (serve === 2) {
            serve2All++;
            serve2Success += isSuccess ? 1 : 0;
            speed2 += speed;
            twoList.push(item);
        }
    });

    const speed1Ave = Math.floor(speed1 / (serve1All || 1));
    const speed2Ave = Math.floor(speed2 / (serve2All || 1));

    return {
        serve1Ratio: Math.floor(serve1Success * 100 / (serve1All || 1)), // 一发成功率
        serve2Ratio: Math.floor(serve2Success * 100 / (serve2All || 1)), // 二发成功率
        serve1Success, // 一发成功数
        serve2Success, // 二发成功数
        serve1Count: serve1All, // 一发数量
        serve2Count: serve2All, // 二发数量
        speed1Ave, // 一发平均球速
        speed2Ave, // 二发平均球速
        oneList, // 一发数据
        twoList, // 二发数据 
    };
};

// 截击
export const volleyHandle = data => {
    function handleData(pointList) {
        const successList = [];
        pointList?.forEach?.(item => {
            const {x, y, region, targetRegion, heightFromNet} = item;
            const isIn = x >= 0 && x <= 1 && y >= 0 && y <= 1;
            if (isIn) {
                successList.push(item);
            }
        });

        return [successList];
    }

    const {forehand, backhand} = data || {};

    const [forehandSuccess] = handleData(forehand);
    const [backhandSuccess] = handleData(backhand);

    const forehandRatio = Math.floor(forehandSuccess?.length *100 / (forehand?.length || 1));
    const backhandRatio = Math.floor(backhandSuccess?.length *100 / (backhand?.length || 1));
    
    return {
        forehandRatio, // 正手截击成功率
        backhandRatio, // 反手截击成功率
        forehand,
        backhand,
    };
};

// 移动数据
export const moveHandle = moveData => {
    const {
        leftRight = 0,
        frontBack = 0,
        combination = 0,
        pace = 0,
        standHeatmap,
    } = moveData || {};

    return {
        leftRight,
        frontBack,
        combination,
        pace,
        standHeatmap,
    };
};

const cmap = {
    overallScore: overallScoreHandle,
    sportData: sportDataHandle,
    firstLandingPoints: firstLandingPointsHandle,
    secondLandingPoints: secondLandingPointsHandle,
    posture: postureHandle,
    slice: sliceHandle,
    dropShot: dropShotHandle,
    serve: serveHandle,
    volley: volleyHandle,
    move: moveHandle,
};

export const formatData = (type, resultData) => {
    return cmap?.[type]?.(resultData?.[type]);
};

export const formatAllData = resultData => {
    const result = {};
    for (const type in cmap) {
        result[type] = formatData(type, resultData);
    }
    return result;
};


