import './index.css';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Form, Input, Tabs, Space, Slider, Switch, Table, Spin, Select, Modal, Col, Row, message } from "antd";
import {DrawNtrp} from '../../canvas_util/draw-ntrp';



export const DrawOverallScore = props => {
	const dataType = 'overallScore';
	const {data, onChange} = props;
	const canvasRef = useRef();
	const drawObjRef = useRef();
	const [renderData, setRenderData] = useState({});

	const inputChange = (type, value) => {

		renderData[type] = value;
		const newRenderData = {...renderData};
		setRenderData(newRenderData);
		renderCanvas(renderData);
	};

	const renderCanvas = data => {
		drawObjRef.current = drawObjRef.current || new DrawNtrp(canvasRef.current);
		drawObjRef.current.draw(data);
	};


	useEffect(() => {
		const renderData = data || {
			backhand: 0,
			forehand: 0,
			move: 0,
			netFront: 0,
			ntrp: 0,
			serve: 0,
			tactics: 0
		};
		renderData.backhand = renderData.backhand || 0;
		renderData.forehand = renderData.forehand || 0;
		renderData.move = renderData.move || 0;
		renderData.netFront = renderData.netFront || 0;
		renderData.ntrp = renderData.ntrp || 0;
		renderData.serve = renderData.serve || 0;
		renderData.tactics = renderData.tactics || 0;

		setRenderData(renderData);

		renderCanvas(renderData);
	}, [data]);

	useEffect(() => {
		onChange?.(dataType, renderData);
	}, [renderData]);


	return <>
		<Row>
			<Col span={12}>
				<Row>
					<Col span={4}>NTRP：</Col>
					<Col span={18}><Slider max={5} step={0.5} min={0} onChange={(value) => inputChange('ntrp', value)} value={renderData?.ntrp || 0}/></Col>
				</Row>
				<Row>
					<Col span={4}>正手：</Col>
					<Col span={18}><Slider max={5} step={0.5} min={0} onChange={(value) => inputChange('forehand', value)} value={renderData?.forehand || 0}/></Col>
				</Row>
				<Row>
					<Col span={4}>反手：</Col>
					<Col span={18}><Slider max={5} step={0.5} min={0} onChange={(value) => inputChange('backhand', value)} value={renderData?.backhand || 0}/></Col>
				</Row>
				<Row>
					<Col span={4}>发球：</Col>
					<Col span={18}><Slider max={5} step={0.5} min={0} onChange={(value) => inputChange('serve', value)} value={renderData?.serve || 0}/></Col>
				</Row>
				<Row>
					<Col span={4}>战术：</Col>
					<Col span={18}><Slider max={5} step={0.5} min={0} onChange={(value) => inputChange('tactics', value)} value={renderData?.tactics || 0}/></Col>
				</Row>
				<Row>
					<Col span={4}>网前：</Col>
					<Col span={18}><Slider max={5} step={0.5} min={0} onChange={(value) => inputChange('netFront', value)} value={renderData?.netFront || 0}/></Col>
				</Row>
				<Row>
					<Col span={4}>移动：</Col>
					<Col span={18}><Slider max={5} step={0.5} min={0} onChange={(value) => inputChange('move', value)} value={renderData?.move || 0}/></Col>
				</Row>
			</Col>
			<Col span={12}><canvas style={{width: '100%'}} ref={canvasRef}/></Col>
		</Row>
	</>
};