import './index.css';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Form, Input, Tabs, Space, Radio, InputNumber, Flex, Slider, Switch, Table, Spin, Select, Modal, Col, Row, message } from "antd";
import {DrawPosture} from '../../canvas_util/draw-posture';



export const DrawPoseView = props => {
	const dataType = 'posture';
	const {data, onChange} = props;
	const canvasRef = useRef();
	const drawObjRef = useRef();
	const changdiRef = useRef();
	const [renderData, setRenderData] = useState({});
	const xMaxRadius = 250;
	const yMaxRadius = 150;


	const inputChange = (type, value) => {
		renderData.forehand = renderData.forehand || {};
		renderData.backhand = renderData.backhand || {};
		if (type === 'forehand.count') {
			renderData.forehand.count = value;
		} else if (type === 'forehand.integrity') {
			renderData.forehand.integrity = value;
		} else if (type === 'backhand.count') {
			renderData.backhand.count = value;
		} else if (type === 'backhand.integrity') {
			renderData.backhand.integrity = value;
		}

		setRenderData({...renderData});

	};

	const renderCanvas = data => {
		drawObjRef.current = drawObjRef.current || new DrawPosture(canvasRef.current);
		drawObjRef.current.draw(data);
	};

	// 点击位置回调
	const pointClick = event => {
		event.stopPropagation();
		event.preventDefault();

		const react = changdiRef.current.getBoundingClientRect();
		const top = event.pageY - document.documentElement.scrollTop - react.top;
		const left = event.pageX - document.documentElement.scrollLeft - react.left;
		const {width, height} = react;
		const centerX = width / 2;
		const centerY = height * 0.66;
		const isLeft = left < centerX;
		const isTop = top < centerY;
		const halfWidth = width / 2;
		const topHeight = height * 0.66;


		const x = (left - halfWidth) / halfWidth * xMaxRadius;
		const y = (topHeight - top) / topHeight * yMaxRadius;
		renderData.backhand = renderData.backhand || {};
		renderData.forehand = renderData.forehand || {};
		renderData.forehand.hittingPoints = renderData.forehand.hittingPoints || [];
		renderData.backhand.hittingPoints = renderData.backhand.hittingPoints || [];
		if (isLeft) {
			renderData.backhand.hittingPoints.push({x, y});
		} else {
			renderData.forehand.hittingPoints.push({x, y});
		}
		setRenderData({...renderData});
	};


	const removePoint = (type, index, event) => {
		event.stopPropagation();
		event.preventDefault();
		renderData[type].hittingPoints.splice(index, 1);
		setRenderData({...renderData});
	};


	useEffect(() => {

		setRenderData(data || {
			forehand: {
				count: 0,
				integrity: 0,
				hittingPoints: [],
			},
			backhand: {
				count: 0,
				integrity: 0,
				hittingPoints: [],
			},
		});

	}, [data]);

	useEffect(() => {
		renderCanvas(renderData || {});
		onChange?.(dataType, renderData);
	}, [renderData]);

	const renderPointList = (type, pointList) => {
	// 	const xMaxRadius = 250;
	// 	const yMaxRadius = 150;
		if (!changdiRef.current) {
			return <></>;
		}
		const react = changdiRef.current.getBoundingClientRect();
		const {width, height} = react;
		const halfWidth = width / 2;
		const topHeight = height * 0.66;
		return <>{
			pointList?.map?.((item, index) => {
				const {x, y} = item;
				const style = {
					left: halfWidth + halfWidth * x / xMaxRadius + 'px',
					top:  topHeight * (1 - y / yMaxRadius) + 'px',
				};

				return <div style={style} onContextMenu={(event) => removePoint(type, index, event)} className={`cpoint cpoint-${x<0?'left':'right'}`}></div>
			})
		}</>
	};



	return <>
		<Row>
			<Col span={12}>
				<div className='pointsel' ref={changdiRef} onClick={pointClick}>
					<div className='pointsel-left-top'></div>
					<div className='pointsel-right-top'></div>
					<div className='pointsel-left-bottom'></div>
					<div className='pointsel-right-bottom'></div>
					<div className='pointsel-lefttext'>反手</div>
					<div className='pointsel-righttext'>正手</div>
					{
						renderPointList('forehand', renderData?.forehand?.hittingPoints)
					}
					{
						renderPointList('backhand', renderData?.backhand?.hittingPoints)
					}
				</div>
				<div style={{fontSize: '12px', color: '#999', lineHeight: '14px'}}>点右键删除点</div>
				<div className='mt5'><InputNumber value={renderData?.forehand?.count || 0} onChange={(value) => inputChange('forehand.count', value)} min={0} addonBefore="正手击球次数" addonAfter="次"/></div>
				<div className='mt5'><InputNumber value={renderData?.forehand?.integrity || 0} onChange={(value) => inputChange('forehand.integrity', value)} min={0} max={100} addonBefore="正手挥拍完整度" addonAfter="%"/></div>
				<div className='mt5'><InputNumber value={renderData?.backhand?.count || 0} onChange={(value) => inputChange('backhand.count', value)} min={0} addonBefore="反手击球次数" addonAfter="次"/></div>
				<div className='mt5'><InputNumber value={renderData?.backhand?.integrity || 0} onChange={(value) => inputChange('backhand.integrity', value)} min={0} max={100} addonBefore="反手挥拍完整度" addonAfter="%"/></div>
			</Col>
			<Col span={12}><canvas style={{width: '100%'}} ref={canvasRef}/></Col>
		</Row>
	</>
};