import './index.css';
import React, { useEffect, useRef, useState } from 'react';
import { Button,Collapse , Checkbox, Form, Input, InputNumber, Tabs, Space, Slider, Switch, Table, Spin, Select, Modal, Col, Row, message } from "antd";
import {DrawOverallScore} from '../draw_ntrp';
import {DrawFirstPoint} from '../draw_first_point';
import {DrawSport} from '../draw_sport';
import {DrawPowerView} from '../draw_power';
import {DrawPoseView} from '../draw_pose_view';
import {DrawSliceView} from '../draw_slice_view';
import {DrawDropShotView} from '../draw_drop_shot_view';
import {DrawServeView} from '../draw_serve_view';
import {DrawVolleyView} from '../draw_volley_view';
import {DrawMoveView} from '../draw_move_view';
import {adminEditOrderResult} from '../../util/requestUtil';

export const ChatPage = props => {
	const {orderData} = props;
	const [chatData, setChatData] = useState({});
	const chatDataRef = useRef({});

	const dataChange = (dataType, data) => {
		chatDataRef.current[dataType] = data;
	};

	const saveCharData = async () => {
		console.log('save', orderData.id, chatDataRef.current);
		const confirm = window.confirm('确认保存图表');
		if (confirm) {
			const res = await adminEditOrderResult(orderData.id, chatDataRef.current);
			if (res.error === 0) {
				message.success('保存成功');
			} else {
				message.error('保存失败:' + res.error);
			}
		}
	};

	const items = [
		{
			label: 'NTRP',
			children: <DrawOverallScore onChange={dataChange} data={chatData?.overallScore}/>,
		},
		{
			label: '运动概况',
			children: <DrawSport onChange={dataChange} data={chatData?.sportData}/>,
		},
		{
			label: '落点',
			children: <DrawFirstPoint onChange={dataChange} data={chatData?.firstLandingPoints}/>,
		},
		{
			label: '力量',
			children: <DrawPowerView onChange={dataChange} data={chatData?.secondLandingPoints}/>,
		},
		{
			label: '姿态',
			children: <DrawPoseView onChange={dataChange} data={chatData?.posture}/>,
		},
		{
			label: '切削',
			children: <DrawSliceView onChange={dataChange} data={chatData?.slice}/>,
		},
		{
			label: '小球',
			children: <DrawDropShotView onChange={dataChange} data={chatData?.dropShot}/>,
		},
		{
			label: '发球技术',
			children: <DrawServeView onChange={dataChange} data={chatData?.serve}/>,
		},
		{
			label: '网前技术',
			children: <DrawVolleyView onChange={dataChange} data={chatData?.volley}/>,
		},
		{
			label: '移动数据',
			children: <DrawMoveView onChange={dataChange} data={chatData?.move}/>,
		}
	];


	const defaultActiveKey = [];
	items.forEach((item, index) => {
		item.key = `${index + 1}`;
		defaultActiveKey.push(item.key);
	});

	useEffect(() => {
		if (orderData) {
			const result = orderData.result;

			chatDataRef.current = {...result};

			setChatData({...result});
		}
	}, [orderData]);

	return <>
		<Collapse items={items} defaultActiveKey={defaultActiveKey} />
		{orderData?.id && <div style={{marginTop: '10px', textAlign: 'center'}}>
			<Button type="primary" onClick={saveCharData}>保存图表</Button>
		</div>}
	</>
};