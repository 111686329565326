import './index.css';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Form, Input, Tabs, Space, Slider, Flex, Radio, Switch, Table, Spin, Select, Modal, Col, Row, message, InputNumber } from "antd";
import {DrawFirstLand} from '../../canvas_util/draw-first-land';
import {PointSelect} from '../point_select';




export const DrawFirstPoint = props => {
	const dataType = 'firstLandingPoints';
	const {data, onChange} = props;
	const canvasRef = useRef();
	const drawObjRef = useRef();
	const [renderData, setRenderData] = useState([]);
	const [showPointList, setShowPointList] = useState([]);
	const [itemData, setItemData] = useState({});

	const setNewItemData = data => {
		setItemData({
			isInTargetRegion: 0,
			isBackhand: 0,
			depth: 0,
			...data,
		});
	};

	const inputChange = (type, value) => {
		itemData[type] = value || 0;

		if (type === 'isInTargetRegion') {
			value = value ? 1 : 0;
		}

		console.log(type, value)

		setItemData({...itemData});
	};

	const renderCanvas = data => {
		drawObjRef.current = drawObjRef.current || new DrawFirstLand(canvasRef.current);
		drawObjRef.current.draw(data);
	};

	// 点击位置回调
	const pointClick = data => {
		const {x, y} = data;
		const isOut = x < 0 || x > 1 || y < 0 || y > 1;
		const newItemData = {
			...itemData,
			x, y,
			isOut: isOut ? 1 : 0,
		};
		setItemData(newItemData);
	};

	const addPoint = data => {
		const keyList = ['isInTargetRegion', 'isBackhand', 'depth', 'isOut', 'x', 'y'];
		for (let i = 0; i < keyList.length; i++) {
			const key = keyList[i];
			if (itemData[key] === undefined) {
				alert(key + '未设置');
				return;
			}
		}

		itemData.isInTargetRegion = itemData.isInTargetRegion ? 1 : 0;
		itemData.isBackhand = itemData.isBackhand ? 1 : 0;

		const newList = [...renderData, itemData];
		console.log('newList',newList)
		setRenderData(newList);
		setNewItemData();
	};

	const removePoint = index => {
		if (renderData[index]) {
			renderData.splice(index, 1);
			const newList = [...renderData];
			setRenderData(newList);
		}
	};

	useEffect(() => {
		setNewItemData();
	}, []);

	useEffect(() => {

		setRenderData(data || []);

	}, [data]);

	useEffect(() => {
		renderCanvas(renderData || []);
		onChange?.(dataType, renderData);
	}, [renderData]);

	useEffect(() => {
		const pointList = [...renderData]
		if (itemData?.x !== undefined) {
			pointList.push(itemData);
		}
		setShowPointList(pointList);
	}, [renderData, itemData]);



	const depthFormatter = (value) => `${value}cm`;

	return <>
		<Row>
			<Col span={12}>
				<Space>
					<PointSelect clickHandle={pointClick} pointList={showPointList} pointClick={removePoint}/>
				</Space>
				<div style={{fontSize: '12px', color: '#999', lineHeight: '14px'}}>点右键删除点</div>
				<Flex>
					<InputNumber min={0} onChange={(value) => inputChange('depth', value)} value={itemData?.depth || 0} addonBefore='深度' addonAfter='m' />
				</Flex>
				<Space>
					<Radio.Group
						value={itemData.isBackhand || 0}
						options={[{ value: 0, label: '正手' }, { value: 1, label: '反手' },]}
						onChange={(event) => inputChange('isBackhand', event.target.value)}
					/>
					<Checkbox checked={!!itemData.isInTargetRegion} onChange={(event) => inputChange('isInTargetRegion', event.target.checked)}>目标落点</Checkbox>
				</Space>
				<div><Button size='small' type="primary" onClick={addPoint}>添加</Button></div>
			</Col>
			<Col span={12}><canvas style={{width: '100%'}} ref={canvasRef}/></Col>
		</Row>
	</>
};