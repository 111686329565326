import { CustomCanvas } from './canvas';
import {CanvasArc} from './canvas-arc';
import {createCanvas} from '../utils/canvas-util';


export const getForehandBackhandRatio = function (canvasDom, config, notBackImage) {
    const {
        title,
        ratioList, // [外环, 内环] 内外环比例
        subTitleList, // [左侧, 右侧] 左右侧小标题
        center,
        scale = 3,
        signSize = 132,
    } = config;

    const width = signSize * scale;
    const height = signSize * scale;
    // const canvas = canvasDom || wx.createOffscreenCanvas({
    //     type: '2d',
    //     width,
    //     height,
    // });

    const canvas = createCanvas(canvasDom, width, height);

    const customCanvas = new CustomCanvas(canvas, {scale});

    const canvasArc = new CanvasArc(canvas, {
        center: center || [width / 2, height / 2],
        maxRadius: width / 2 - 5 * scale,
        roundSpace: 3 * scale,
        lineWidth: 7 * scale,
    });


    function render() {
        customCanvas.clear();
        const backhandAngle = 360 * ratioList[0];
        const forehandAngle = 360 * ratioList[1];
        canvasArc.setData([
			{angle: forehandAngle, color: '#DDE934', bgColor: '#F8FBD6'},
			{angle: backhandAngle, color: '#55D7AB', bgColor: '#55D787', bgAlpha: 0.1, dataLineWidth: 6 * scale, backLineWidth: 6 * scale}
		]);

        customCanvas.fillTextByHorizontalCenter(title, '#333', 9, signSize / 2, 50, true);
		customCanvas.fillText(subTitleList[0], '#999999', 10, 35, 68, false);
		customCanvas.fillText(subTitleList[1], '#999999', 10, 75, 68, false);
		customCanvas.fillTextByHorizontalCenter((ratioList[0] * 100).toFixed(0), '#55D7AB', 20, 45, 92, true);
		customCanvas.fillTextByHorizontalCenter((ratioList[1] * 100).toFixed(0), '#DDE934', 20, 85, 92, true);
    }

    render();
    if (!notBackImage) {
        const imageUrl = customCanvas.toDataURL('image/png');
        return imageUrl;
    }
};


