import {canvasToTempFilePath, loadImage} from './canvas-util';

export const CustomCanvas = function (canvas, config) {
    const {scale = 1} = config || {};
    const ctx = canvas.getContext('2d');
    const width = canvas.width;
    const height = canvas.height;

    this.getSize = () => {
        return {
            width,
            height,
            scale,
        };
    };

    this.clear = () => {
        ctx.clearRect(0, 0, width, height);
    };

    // 画圆形图
    this.drawCircleImage = async (imgUrl, centerX, centerY, radius) => {
        try {
            centerX = centerX * scale;
            centerY = centerY * scale;
            radius = radius * scale;
            const img = await loadImage(imgUrl, canvas);
            ctx.save();
            ctx.beginPath();
            ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
            ctx.clip();
            const drawSize = 2 * radius;
            ctx.drawImage(img, 0, 0, img.width, img.height, centerX - radius, centerY - radius, drawSize, drawSize);
            ctx.restore();
        } catch (e) {
            return false;
        }
        return true;
    };

    this.drawImage = async (imgUrl, left, top, width, height) => {
        left *= scale;
        top *= scale;
        width *= scale;
        height *= scale;
        try {
            const img = await loadImage(imgUrl, canvas);
            ctx.drawImage(img, 0, 0, img.width, img.height, left, top, width, height);
        } catch (e) {
        }
    };

    this.setShadow = (color, blur, offsetX, offsetY) => {
        ctx.shadowColor = color;
        ctx.shadowBlur = blur * scale;
        ctx.shadowOffsetX = offsetX * scale;
        ctx.shadowOffsetY = offsetY * scale;
    };

    this.resetShadow = () => {
        ctx.shadowColor = null;
        ctx.shadowBlur = null;
        ctx.shadowOffsetX = null;
        ctx.shadowOffsetY = null;
    };

    this.fillRoundReact = (width, height, radius, color) => {
        this.fillRoundReactForPos(0, 0, width, height, radius, color);
    };
    // 圆角矩形
    this.fillRoundReactForPos = (left, top, width, height, radius, color, angle = 0) => {
        left *= scale;
        top *= scale;
        width *= scale;
        height *= scale;
        radius *= scale;

        ctx.save();
        ctx.translate(left, top);
        if (angle) {
            ctx.rotate(angle * Math.PI / 180);
        }
        ctx.beginPath();
        ctx.moveTo(radius, 0); // 左上横线点
        ctx.lineTo(width - radius, 0); // 上横线
        ctx.arcTo(width, 0, width, radius, radius); // 右上角圆角
        ctx.lineTo(width, height -radius); // 右侧竖线
        ctx.arcTo(width, height, width - radius, height, radius); // 右下角圆角
        ctx.lineTo(radius, height); // 下边线
        ctx.arcTo(0, height, 0, height - radius, radius); // 左下角圆角
        ctx.lineTo(0, radius); // 左边竖线
        ctx.arcTo(0, 0, radius, 0, radius);

        // ctx.lineWidth = 1;
        ctx.closePath();
        ctx.fillStyle = color;
        ctx.fill();
        ctx.restore();
        this.resetShadow();
    };

    // 圆角矩形框
    this.strokeRoundReactForPos = (left, top, width, height, radius, color, lineWidth) => {
        left *= scale;
        top *= scale;
        width *= scale;
        height *= scale;
        radius *= scale;
        lineWidth *= scale;

        ctx.save();
        ctx.translate(left, top);
        ctx.beginPath();
        ctx.moveTo(radius, 0); // 左上横线点
        ctx.lineTo(width - radius, 0); // 上横线
        ctx.arcTo(width, 0, width, radius, radius); // 右上角圆角
        ctx.lineTo(width, height -radius); // 右侧竖线
        ctx.arcTo(width, height, width - radius, height, radius); // 右下角圆角
        ctx.lineTo(radius, height); // 下边线
        ctx.arcTo(0, height, 0, height - radius, radius); // 左下角圆角
        ctx.lineTo(0, radius); // 左边竖线
        ctx.arcTo(0, 0, radius, 0, radius);

        ctx.lineWidth = lineWidth;
        ctx.closePath();
        ctx.strokeStyle = color;
        ctx.stroke();
        ctx.restore();
        this.resetShadow();
    };

    this.fillRect = (x, y, width, height, color) => {
        x *= scale;
        y *= scale;
        width *= scale;
        height *= scale;
        ctx.beginPath();
        ctx.fillStyle = color;
        ctx.fillRect(x, y, width, height);
        ctx.closePath();
        // ctx.fill();
    };

    this.fillTextByHorizontalCenter = (text, color, size, centerX, top, isBold) => {
        ctx.font = `${size}px Arial`;
        const width = ctx.measureText(text).width;
        const left = centerX - width / 2;
        this.fillText(text, color, size, left, top, isBold);
    };

    this.fillTextByRight = (text, color, size, right, top, isBold) => {
        ctx.font = `${size}px Arial`;
        const width = ctx.measureText(text).width;
        const left = right - width;
        this.fillText(text, color, size, left, top, isBold);
    };

    // 指定圆心画圆
    this.fillRoundByCenter = (centerX, centerY, radius, color) => {
        this.fillRound(centerX - radius, centerY - radius, radius, color);
    };

    // 指定圆心画圆圈
    this.strokeRoundByCenter = (centerX, centerY, radius, color, lineWidth) => {
        this.strokeRound(centerX - radius, centerY - radius, radius, color, lineWidth);
    };

    // 画圆
    this.fillRound = (left, top, radius, color) => {
        left *= scale;
        top *= scale;
        radius *= scale;
        ctx.beginPath();
        ctx.fillStyle = color;
        ctx.arc(left, top, radius, 0, Math.PI * 2);
        ctx.closePath();
        ctx.fill();
    };

    this.strokeRound = (left, top, radius, color, lineWidth) => {
        left *= scale;
        top *= scale;
        radius *= scale;
        ctx.beginPath();
        ctx.strokeStyle = color;
        ctx.lineWidth = lineWidth;
        ctx.arc(left, top, radius - lineWidth / 2, 0, Math.PI * 2);
        ctx.closePath();
        ctx.stroke();
    };

    this.fillText = (text, color, size, left, top, isBold) => {
        size *= scale;
        left *= scale;
        top *= scale;
        ctx.font = `${isBold ? 'bold ':''}${size}px Arial`;
        ctx.fillStyle = color;
        ctx.fillText(text, left, top);

        const result = {
            width: ctx.measureText(text).width / scale,
            height: size / scale,
            left: left / scale,
            top: top / scale,
        };

        return result;
    };

    this.drawLines = (points, color, lineWidth = 1) => {
        ctx.beginPath();
        points.forEach((point, index) => {
            const {x, y} = point;
            if (index === 0) {
                ctx.moveTo(x * scale, y * scale);
            } else {
                ctx.lineTo(x * scale, y * scale);
            }
        });

        ctx.lineWidth = lineWidth * scale;
        ctx.strokeStyle = color;
        ctx.stroke();
    };

    this.fillLines = (points, color) => {
        ctx.beginPath();
        points.forEach((point, index) => {
            const {x, y} = point;
            if (index === 0) {
                ctx.moveTo(x * scale, y * scale);
            } else {
                ctx.lineTo(x * scale, y * scale);
            }
        });

        ctx.fillStyle = color;
        ctx.fill();
    };

    // 设置虚线
    this.setLineDash = (data) => {
        ctx.setLineDash([data[0] * scale, data[1] * scale]);
    };
    // 取消虚线
    this.resetLineDash = () => {
        ctx.setLineDash([]);
    };

    this.getImageData = () => {
        ctx.getImageData(0, 0, width, height);
    };

    this.toTempFilePath = () => canvasToTempFilePath(canvas);

    this.toDataURL = () => {
        return canvas.toDataURL('image/png');
    };
};


