import './index.css';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Form, Input, Tabs, Space, InputNumber, Slider, Switch, Table, Spin, Select, Modal, Col, Row, message } from "antd";
import {SportOverview} from '../../canvas_util/sport-overview';



export const DrawSport = props => {
	const dataType = 'sportData';
	const {data, onChange} = props;
	const canvasRef = useRef();
	const drawObjRef = useRef();
	const [renderData, setRenderData] = useState({});
	const timerRef = useRef();

	const inputChange = (type, value) => {

		renderData[type] = value;
		const newRenderData = {...renderData};
		setRenderData(newRenderData);
	};

	const renderCanvas = data => {
		drawObjRef.current = drawObjRef.current || new SportOverview(canvasRef.current);
		// drawObjRef.current.draw(data);

		clearTimeout(timerRef.current);
		timerRef.current = setTimeout(() => {
			drawObjRef.current.draw(data);
		}, 200);
	};


	useEffect(() => {
		const renderData = data || {
			averageBattingSpeed: 0,
			battingCount: 0,
			burnCalories: 0,
			moveDistance: 0,
			sportDuration: 0,
		};

		setRenderData(renderData);

	}, [data]);

	useEffect(() => {
		renderCanvas(renderData || []);
		onChange?.(dataType, renderData);
	}, [renderData]);



	return <>
		<Row>
			<Col span={12}>
				<Row>
					<Col span={6}>运动时长：</Col>
					<Col span={18}><InputNumber addonAfter="小时" value={renderData.sportDuration} onChange={(value) => inputChange('sportDuration', value)}/></Col>
				</Row>
				<Row>
					<Col span={6}>平均球速：</Col>
					<Col span={18}><InputNumber addonAfter="km/h" value={renderData.averageBattingSpeed} onChange={(value) => inputChange('averageBattingSpeed', value)}/></Col>
				</Row>
				<Row>
					<Col span={6}>移动距离：</Col>
					<Col span={18}><InputNumber addonAfter="m" value={renderData.moveDistance} onChange={(value) => inputChange('moveDistance', value)}/></Col>
				</Row>
				<Row>
					<Col span={6}>击球次数：</Col>
					<Col span={18}><InputNumber addonAfter="次" value={renderData.battingCount} onChange={(value) => inputChange('battingCount', value)}/></Col>
				</Row>
				<Row>
					<Col span={6}>消耗热量：</Col>
					<Col span={18}><InputNumber addonAfter="cal" value={renderData.burnCalories} onChange={(value) => inputChange('burnCalories', value)}/></Col>
				</Row>
			</Col>
			<Col span={12}><canvas style={{width: '100%'}} ref={canvasRef}/></Col>
		</Row>
	</>
};