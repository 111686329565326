// 发球技术
import {CustomCanvas} from '../utils/canvas';
import {CanvasRadar} from '../utils/canvas-radar';
import {CanvasArc} from '../utils/canvas-arc';
import {getForehandBackhandRatio} from '../utils/forehand-backhand-ratio';
import {drawPointMap} from './point-map';
import {sliceHandle} from '../utils/data-util';
import {createCanvas} from '../utils/canvas-util';

// 切削数据
export const DrawSlice = function(canvas) {
    const scale = 3;
	const width = 327;
	const height = 218;

    canvas = createCanvas(canvas, width, height, scale);

    const customCanvas = new CustomCanvas(canvas, {scale});


    this.draw = async (sliceData) => {
        customCanvas.clear();

        const {
            forehandHeightAve, // 正手平均深度
            backhandHeightAve, // 反手平均深度
            forehand,
            backhand,
            maxHeightFromNet, // 最大过网高度
            minHeightFromNet, // 最小过网高度
        } = sliceHandle(sliceData);


        customCanvas.fillText('平均过网高度', '#333333', 10, 43, 12, true);
        customCanvas.fillText('落点分析', '#333333', 10, 222, 12, true);

        customCanvas.setShadow('rgba(171, 181, 30, 0.2)', 3, 0 , 0);
        customCanvas.fillRoundReactForPos(48, 32,10, 134, 5, 'rgba(221, 233, 52, 0.1)');

        customCanvas.setShadow('rgba(28,173,124, 0.2)', 12, 0 , 0);
        customCanvas.fillRoundReactForPos(88, 32,10, 134, 5, '#EEFBF7');

        const lineY = 37 + 124 * 0.8;
        drawPoints(forehand, 'rgba(221, 233, 52, 0.4)', 53, lineY, 99, 25, maxHeightFromNet, minHeightFromNet);
        drawPoints(backhand, 'rgba(85, 215, 171, 0.4)', 93, lineY, 99, 25, maxHeightFromNet, minHeightFromNet);

        
        customCanvas.fillRect(29, lineY, 87, 1, '#F98E23');
        customCanvas.fillLines([{x: 29, y: lineY - 5}, {x: 29 + 10, y: lineY}, {x: 29 + 10, y: lineY + 1}, {x: 29, y: lineY + 6}], '#F98E23');
        customCanvas.fillLines([{x: 116, y: lineY - 5}, {x: 116 - 10, y: lineY}, {x: 116 - 10, y: lineY + 1}, {x: 116, y: lineY + 6}], '#F98E23');
        customCanvas.fillText('网高', '#F98E23', 9, 64, 130);

        customCanvas.fillRound(16, 186, 2, '#DDE934');
        let textRes = customCanvas.fillText('正手平均过网高度(mm):', '#000', 8, 24, 189);
        customCanvas.fillText(forehandHeightAve, '#DDE934', 10, 28 + textRes.width, 191, true);
        customCanvas.fillRound(16, 206, 2, '#55D7AB');
        textRes = customCanvas.fillText('反手平均过网高度(mm):', '#000', 8, 24, 209);
        customCanvas.fillText(backhandHeightAve, '#1CAD7C', 10, 28 + textRes.width, 211, 1);

        const dataList = [
            {
                text: '正手',
                color: '#DDE934',
                list: forehand,
            },
            {
                text: '反手',
                color: '#55D7AB',
                list: backhand,
            },
            {
                text: '界内',
                color: '#111',
                list: [],
            },
            {
                text: '界外',
                color: '#111',
                isRing: true,
                list: [],
            },
        ];
        const mapImg = await drawPointMap(dataList);
        await customCanvas.drawImage(mapImg, 175, 39, 138, 160);

    };

    function drawPoints(pointList, color, left, top, topHeight, bottomHeight, topMax, bottomMax) {
        pointList.forEach(point => {
            const {x, y, heightFromNet} = point;
            let drawTop = 0;
            topMax = topMax || 50;
            bottomMax = bottomMax || 30;
            if (heightFromNet > 0) {
                drawTop = top - topHeight * heightFromNet / topMax;
            } else {
                drawTop = top + bottomHeight * heightFromNet / bottomMax;
            }

            customCanvas.fillRound(left, drawTop, 5, color)
        });
    }

    this.toDataURL = customCanvas.toDataURL;
    this.toTempFilePath = customCanvas.toTempFilePath;
    this.getSize = () => ({
        width,
        height,
        scale,
    });
};
