import './index.css';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Image, Upload, Input, Tabs, Space, Slider, Switch, Table, Spin, Select, Modal, Col, Row, message } from "antd";
import {DrawMove} from '../../canvas_util/draw-move';



export const DrawMoveView = props => {
	const changdimap = 'https://aicoach.karryai.tech/static/images/img81.png';
	const dataType = 'move';
	const {data, onChange} = props;
	const canvasRef = useRef();
	const drawObjRef = useRef();
	const [renderData, setRenderData] = useState({});

	const inputChange = (type, value) => {

		renderData[type] = value;
		const newRenderData = {...renderData};
		setRenderData(newRenderData);
	};

	const renderCanvas = data => {
		drawObjRef.current = drawObjRef.current || new DrawMove(canvasRef.current);
		drawObjRef.current.draw(data);
	};


	useEffect(() => {
		const renderData = data || {
			combination: 0,
			frontBack: 0,
			leftRight: 0,
			pace: 0,
			standHeatmap: changdimap,
		};

		renderData.standHeatmap = renderData.standHeatmap || changdimap;

		setRenderData(renderData);

	}, [data]);

	useEffect(() => {
		renderCanvas(renderData);
		onChange?.(dataType, renderData);
	}, [renderData]);

	const uploadprops = {
		maxCount: 1,
		listType: 'picture',
		name: 'file',
		action: '/upload/file',
		headers: {
		},
		onChange(info) {
			if (info.file.status !== 'uploading') {
				console.log(info.file, info.fileList);
			}
			if (info.file.status === 'done') {
				message.success(`${info.file.name} file uploaded successfully`);
				const imageUrl = info?.file?.response?.data?.file;
				if (imageUrl) {
					renderData.standHeatmap = imageUrl;
					setRenderData({...renderData});
				}
			} else if (info.file.status === 'error') {
				message.error(`${info.file.name} file upload failed.`);
			}
		},
	};


	return <>
		<Row>
			<Col span={12}>
				<Row>
					<Col span={6}>左右移动：</Col>
					<Col span={18}><Slider max={100} min={0} onChange={(value) => inputChange('leftRight', value)} value={renderData?.leftRight || 0}/></Col>
				</Row>
				<Row>
					<Col span={6}>前后移动：</Col>
					<Col span={18}><Slider max={100} min={0} onChange={(value) => inputChange('frontBack', value)} value={renderData?.frontBack || 0}/></Col>
				</Row>
				<Row>
					<Col span={6}>组合球：</Col>
					<Col span={18}><Slider max={100} min={0} onChange={(value) => inputChange('combination', value)} value={renderData?.combination || 0}/></Col>
				</Row>
				<Row>
					<Col span={6}>脚步调整：</Col>
					<Col span={18}><Slider max={100} min={0} onChange={(value) => inputChange('pace', value)} value={renderData?.pace || 0}/></Col>
				</Row>
				<Row>
					<Col span={24}>
						<Upload {...uploadprops}>
							<div>
								站位热力图
								<span style={{fontSize: '12px', color: '#999', lineHeight: '14px', paddingLeft: '10px'}}>点击上传热力图</span>
							</div>
							<Image preview={false} width={100} src={renderData.standHeatmap}/>
						</Upload>
					</Col>
				</Row>
			</Col>
			<Col span={12}><canvas style={{width: '100%'}} ref={canvasRef}/></Col>
		</Row>
	</>
};