// 运动概况
import {CustomCanvas} from '../utils/canvas';
import {CanvasRadar} from '../utils/canvas-radar';
import {CanvasArc} from '../utils/canvas-arc';
import {sportDataHandle} from '../utils/data-util';
import {createCanvas} from '../utils/canvas-util';

export const SportOverview = function(canvas) {
    const scale = 3;
	const width = 351 - 24;
	const height = 155 - 24;

    canvas = createCanvas(canvas, width, height, scale);

    const customCanvas = new CustomCanvas(canvas, {scale});

    this.draw = async (data) => {
        customCanvas.clear();
        const {
            sportDuration,
            averageBattingSpeed,
            moveDistance,
            battingCount,
            burnCalories,
        } = sportDataHandle(data);

        // (text, color, size, left, top, isBold)
        let textRes = customCanvas.fillText('您的运动时长为：', '#333333', 12, 0, 19);
        let left1 = textRes.width;
        textRes = customCanvas.fillText(sportDuration, '#55D7AB', 20, left1, 19, true);
        textRes = customCanvas.fillText('h', '#55D7AB', 16, left1 + textRes.width + 2, 19, true);

        await customCanvas.drawImage('https://aicoach.karryai.tech/static/images/img16.png', 0, 48, 24, 24);
        customCanvas.fillText('平均球速', '#999', 10, 28, 55);
        textRes = customCanvas.fillText(averageBattingSpeed, '#333', 16, 28, 75);
        customCanvas.fillText('km/h', '#333', 12, 28 + textRes.width + 2, 75);

        await customCanvas.drawImage('https://aicoach.karryai.tech/static/images/img17.png', 130, 48, 24, 24);
        customCanvas.fillText('移动距离', '#999', 10, 158, 55);
        textRes = customCanvas.fillText(moveDistance, '#333', 16, 158, 75);
        customCanvas.fillText('m', '#333', 12, 158 + textRes.width + 2, 75);

        await customCanvas.drawImage('https://aicoach.karryai.tech/static/images/img18.png', 0, 98, 24, 24);
        customCanvas.fillText('击球次数', '#999', 10, 28, 105);
        textRes = customCanvas.fillText(battingCount, '#333', 16, 28, 125);
        customCanvas.fillText('次', '#333', 12, 28 + textRes.width + 2, 125);

        await customCanvas.drawImage('https://aicoach.karryai.tech/static/images/img19.png', 130, 98, 24, 24);
        customCanvas.fillText('消耗热量', '#999', 10, 158, 105);
        textRes = customCanvas.fillText(burnCalories, '#333', 16, 158, 125);
        customCanvas.fillText('cal', '#333', 12, 158 + textRes.width + 2, 125);

        await customCanvas.drawImage('https://aicoach.karryai.tech/static/images/img20.png', 245, 40, 80, 80);

    };
    this.toDataURL = customCanvas.toDataURL;
    this.toTempFilePath = customCanvas.toTempFilePath;
    this.getSize = () => ({
        width,
        height,
        scale,
    });
};
