

export const CanvasArc = function (canvas, config) {
    const {
        width,
        height,
        center,
        maxRadius,
        roundSpace,
        lineWidth = 6,
        autoClear,
    } = config;
    const ctx = canvas.getContext('2d');
    const centerX = center[0];
    const centerY = center[1];



    function writeArc(angle, radius, color, lineWidth) {
        ctx.beginPath();
        if (angle === 360) {
            ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
        } else {
            ctx.arc(centerX, centerY, radius, angleToRadian(0), angleToRadian(angle), 0);
        }
        
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = color;
        ctx.stroke();
        ctx.closePath();

        writeRound(angle, radius, color, lineWidth);
        writeRound(0, radius, color, lineWidth);
    }

    function writeBackground(radius, color, lineWidth) {
        ctx.beginPath();
        ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
        ctx.lineWidth = lineWidth;
        ctx.strokeStyle = color;
        ctx.stroke();
        ctx.closePath();
    }

    function writeRound(angle, radius, color, lineWidth) {
        ctx.beginPath();
        const {x, y} = getAnglePosition(angle, radius);
        ctx.arc(x, y, lineWidth / 2, 0, 2 * Math.PI);
        ctx.lineWidth = 1;
        ctx.fillStyle  = color;
        ctx.fill();
        ctx.closePath();
    }

    function getAnglePosition(angle, radius) {
        let x = centerX + radius * Math.cos(angleToRadian(angle));
        let y = centerY + radius * Math.sin(angleToRadian(angle));
        return {x, y};
    }

    function angleToRadian(angle) {
        angle = angle % 360 - 90;
        const radian = 2 * Math.PI * angle / 360;
        return radian;
    }

    function clearRect() {
        if (autoClear) {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
        }
    }

    this.setData = (listData) => {
        if (autoClear) {
            clearRect();
        }
        listData.forEach((item, index) => {
            const {angle, color, alpha = 1, bgColor, bgAlpha = 1, backLineWidth, dataLineWidth} = item;
            const radius = maxRadius - (lineWidth + (roundSpace || 0)) * index;
            ctx.globalAlpha = bgAlpha;
            writeBackground(radius, bgColor, backLineWidth || lineWidth);
            ctx.globalAlpha = 1;

            ctx.globalAlpha = alpha;
            writeArc(angle, radius, color, dataLineWidth || lineWidth);
            ctx.globalAlpha = 1;
        });
    };
};
