
import {CustomCanvas} from '../utils/canvas';
import {CanvasRadar} from '../utils/canvas-radar';
import {CanvasArc} from '../utils/canvas-arc';
import {getForehandBackhandRatio} from '../utils/forehand-backhand-ratio';
import {drawPointMap} from './point-map';
import {createCanvas} from '../utils/canvas-util';


// 力量
export const DrawPower = function (canvas) {
    const scale = 3;
	const width = 327;
	const height = 247;

    canvas = createCanvas(canvas, width, height, scale);

    const customCanvas = new CustomCanvas(canvas, {scale});

    this.draw = async (dataList) => {
        customCanvas.clear();
        
        let forehandCount = 0;
        let backhandCount = 0;
        let forehandSpeed = 0;
        let backhandSpeed = 0;
        let forehandList = [];
        let backhandList = [];
        let forehandKineticChain = 0;
        let backhandKineticChain = 0;
        dataList?.forEach?.(item => {
            const {x, y, isBackhand, kineticChain, speed} = item;
            if (!isBackhand) {
                forehandCount++;
                forehandSpeed += speed;
                forehandList.push(item);
                forehandKineticChain += kineticChain;
            } else {
                backhandCount++;
                backhandSpeed += speed;
                backhandList.push(item);
                backhandKineticChain += kineticChain;
            }
        });
        forehandCount = forehandCount || 1;
        backhandCount = backhandCount || 1;

        const jieneiRatioImg = getForehandBackhandRatio(null, {
			title: '动力链分数',
			ratioList: [backhandKineticChain / backhandCount / 100, forehandKineticChain / forehandCount / 100],
			subTitleList: ['反手', '正手'],
		});
        await customCanvas.drawImage(jieneiRatioImg, 15, 23, 132, 132);

        customCanvas.fillText('第二落点分析', '#333333', 10, 219, 12, true);

        customCanvas.fillRect(0, 182, width, 1, '#EEEEEE');
        customCanvas.fillTextByHorizontalCenter('平均球速分析(km/h)', '#333333', 12, width / 2, 206, true);

        customCanvas.fillText('反手', 'rgba(0,0,0,0.4)', 10, 17, 213);
        customCanvas.fillText('正手', 'rgba(0,0,0,0.4)', 10, 286, 213);
        const speed1 = backhandSpeed / backhandCount; // 反手
        const speed2 = forehandSpeed / forehandCount; // 正手
        customCanvas.fillTextByHorizontalCenter(speed1.toFixed(0), '#55D7AB', 20, 25, 214 +27);
        customCanvas.fillTextByHorizontalCenter(speed2.toFixed(0), '#DDE934', 20, 279 + 15, 214 +27);

        const speed1Ratio = speed1 / ((speed1 + speed2) || 1);
        customCanvas.fillRound(63, 218, 4, '#55D7AB');
        customCanvas.fillRound(212 + 59 - 4, 218, 4, '#DDE934');
        const speedWidth = 212 - 8;
        const speed1Width = speed1Ratio * speedWidth;
        customCanvas.fillRect(63, 214, speed1Width, 8, '#55D7AB');
        customCanvas.fillRect(63 + speed1Width, 214, speedWidth - speed1Width, 8, '#DDE934');
        if (speed1Width === 0) {
            customCanvas.fillRound(63, 218, 4, '#DDE934');
        } else if (speed1Width === speedWidth) {
            customCanvas.fillRound(212 + 59 - 4, 218, 4, '#55D7AB');
        }

        forehandList?.forEach?.(item => {
            item.isOut = false;
        });
        backhandList?.forEach?.(item => {
            item.isOut = false;
        });

        console.log('forehandList--', forehandList)

        const mapDataList = [
            {
                text: '正手',
                color: '#DDE934',
                list: forehandList,
            },
            {
                text: '反手',
                color: '#55D7AB',
                list: backhandList,
            },
            // {
            //     text: '界内',
            //     color: '#111',
            //     list: [],
            // },
            // {
            //     text: '界外',
            //     color: '#111',
            //     isRing: true,
            //     list: [],
            // },
        ];
        const mapimage = await drawPointMap(mapDataList, {
            mapScale: 0.7,
        });
        await customCanvas.drawImage(mapimage, 181, 28, 138, 140);
    };

    this.toDataURL = customCanvas.toDataURL;
    this.toTempFilePath = customCanvas.toTempFilePath;
    this.getSize = () => ({
        width,
        height,
        scale,
    });
};
